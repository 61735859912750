import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

import './ket-button.css';

const KetButton = ({ children }) => {
  return (
    <>
      <Button className="ket-button" basic color="blue" size="large">
        {children}
      </Button>
    </>
  );
};

export default KetButton;

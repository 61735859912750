import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';

import Header from '../components/header';
import Layout from '../components/layout';
import KetButton from '../components/ket-button';
// import Menu from '../components/menu';
import SEO from '../components/seo';

import productsImage from '../images/products-rifle-1000x750.jpg';

const Products = () => (
  <Layout>
    <SEO title="Products" />
    <Header />
    {/*<Menu />*/}
    <div>
      <Container className="container__ket myriad-regular" text textAlign="center">
        <p>
          Our products are the new way to solve a common annoyance&mdash;hot, flying
          brass. The Brass-Clip and Brass-Deflector are cost-effective alternatives to
          traditional brass catchers, an ideal training aid for military, law enforcement,
          and performance-focused shooters.
        </p>
        <p>
          We're solving problems and limitations that brass catchers have not been able to
          address&mdash;with heat resistance, easy installation, light weight,
          non-intrusive design, no capacity maximum, and a high quality.
        </p>
      </Container>
    </div>
    <div className="container__ket-blue container__ket-blue__padded">
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column width="8" textAlign="center">
            <Image alt="Brass Clip PR21 Colors" src={productsImage} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    <Container className="container__ket" text textAlign="center">
      <a
        href="https://www.amazon.com/dp/B07VQ2TG2J/ref=sr_1_10?keywords=brass-deflector&qid=1564271022&s=gateway&sr=8-10"
        rel="noreferrer noopener"
        target="_blank"
      >
        <KetButton>See Price on Amazon</KetButton>
      </a>
    </Container>
  </Layout>
);

export default Products;
